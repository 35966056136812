<template>
    <div class="SkipPage" @click="skipPage(title)">
        <!--<img class="img-left" src="../assets/images/yu.png" alt="">-->
        <span class="font-size-15 ">{{title}}</span>
        <img class="img-right" src="../assets/images/youbian.png" alt="">
    </div>
</template>

<script>
    export default {
        name: "SkipPage",
        props: {
            title: String
        },
        methods:{
            skipPage(title) {
                this.$emit("skipPage",title)
            }
        }
    }
</script>

<style scoped>
    .SkipPage {
        display: flex;
        align-items: center;
        margin-bottom: 3rem;
    }

    span {
        flex-grow: 1;
        margin: 0 0.5rem;
    }

    .img-left {
        width: 2.5rem;
        height: 2.5rem;
    }


</style>
