<template>
    <div class="mine">
        <div class="container">
            <top-bar-main middle-text="个人信息"/>
            <div class="info">
                <div class="info-head">
                    <img style="width: 5rem;height: 5rem;border-radius: 5rem;margin-right: 1.5rem"
                         :src="geekPhoto"
                         alt="">
                    <div>
                        <div class="font-size-18 ">{{geekNickName}}</div>
                        <div class="font-size-15">{{geekAccount}}</div>
                    </div>
                </div>
                <div class="info-reward">
                    <div>
                        <div class="font-size-18">{{sumUserAmount}}</div>
                        <div class="font-size-12">可用积分</div>
                    </div>
                    <div>
                        <div class="font-size-18">{{psrPrice}}</div>
                        <div class="font-size-12">ITG实时价格</div>
                    </div>
                </div>

                <div class="font-size-12" style="color: #0091FF;text-align: end;margin: 2rem 0" @click="show=true">
                </div>
                <skip-page title="账单" @skipPage="skipPage($event)"></skip-page>
                <skip-page title="转为可用" @skipPage="skipPage($event)"></skip-page>
                <skip-page title="转让" @skipPage="skipPage($event)"></skip-page>
                <skip-page title="转让订单" @skipPage="skipPage($event)"></skip-page>
<!--                <skip-page title="短拆" @skipPage="skipPage($event)"></skip-page>-->
<!--                <skip-page title="短拆订单" @skipPage="skipPage($event)"></skip-page>-->
                <skip-page title="vip股东" @skipPage="skipPage($event)"></skip-page>
            </div>
        </div>
        <bottom-nav/>
        <van-popup v-model="showToAvl" position="bottom" style="height: 30rem" :lazy-render="false"
                   :round="false">
            <div style="margin:2rem 1.5rem;">
                <div class="flex-between">
                    <span class="font-size-15 font-weight-bold">转为可用</span>
                    <img src="../../assets/images/close.png" style="width: 2rem;height: 2rem" alt=""
                         @click="showToAvl=false">
                </div>

                <div style="height: 5rem;margin-top: 3rem;margin-bottom: 3rem">
                    <input v-model="toAmount" type="number" @input="calcCny" placeholder="输入转为可用数量">
                </div>
                <div style="height: 5rem;margin-top: 3rem;margin-bottom: 3rem">
                    转为可用服务费：<span>{{cnyAmount}}</span>元
                </div>
                <van-button type="info" size="large" @click="itgToAvl">确定</van-button>
            </div>
        </van-popup>
        <van-popup v-model="showShortOrder" position="bottom" style="height: 30rem" :lazy-render="false"
                   :round="false">
            <div style="margin:2rem 1.5rem;">
                <div class="flex-between">
                    <span class="font-size-15 font-weight-bold">短拆</span>
                    <img src="../../assets/images/close.png" style="width: 2rem;height: 2rem" alt=""
                         @click="showShortOrder=false">
                </div>

                <div style="height: 5rem;margin-top: 3rem;margin-bottom: 3rem">
                    <input v-model="shortAmount" type="number" placeholder="输入数量">
                </div>
                <div style="height: 5rem;margin-top: 3rem;margin-bottom: 3rem">
                    可用积分数量：<span>{{sumUserAmount}}</span>
                </div>
                <van-button type="info" size="large" @click="shortOrder">确定</van-button>
            </div>
        </van-popup>
        <van-popup v-model="showPayPasswordDialog" position="bottom" style="height: 24rem" :lazy-render="false"
                   :round="false">
            <div style="margin:2rem 1.5rem;">
                <div class="flex-between">
                    <span class="font-size-15 font-weight-bold">{{title}}</span>
                    <img src="../../assets/images/close.png" style="width: 2rem;height: 2rem" alt=""
                         @click="showPayPasswordDialog=false">
                </div>

                <div style="height: 5rem;margin-top: 3rem;margin-bottom: 3rem">
                    <input v-model="payPassword" type="number" placeholder="输入交易密码">
                </div>

                <van-button type="info" size="large" @click="setPayPassword">确定</van-button>
            </div>
        </van-popup>
        <van-popup v-model="showTransfer" position="bottom" style="height: 32rem" :lazy-render="false"
                   :round="false">
            <div style="margin:1rem 1.5rem;">
                <div class="flex-between">
                    <span class="font-size-15 font-weight-bold">转让</span>
                    <img src="../../assets/images/close.png" style="width: 2rem;height: 2rem" alt=""
                         @click="showTransfer=false">
                </div>
                <div style="height: 3rem;margin-top: 3rem;margin-bottom: 3rem">
                    <span>提示：转让根据市场价值，商家一键收购转让，不保证最终价值与实时进度</span>
                </div>
                <div style="height: 3rem;margin-top: 3rem;margin-bottom: 3rem">
                    <input v-model="amount" type="number" placeholder="输入转让数量">
                </div>
                <div style="height: 3rem;margin-top: 3rem;margin-bottom: 3rem">
                    <input v-model="price" type="number" placeholder="输入转让价格">
                </div>

                <van-button type="info" size="large" @click="transferCommit">转让</van-button>
            </div>
        </van-popup>
    </div>

</template>
<script>
    import BottomNav from "../../components/BottomNav";
    import TopBarMain from "../../components/TopBarMain";

    import SkipPage from "../../components/SkipPage";
    import {Popup, Button, Toast} from "vant";

    export default {
        name: "Mine",
        components: {
            BottomNav,
            TopBarMain,
            SkipPage,
            [Button.name]: Button,
            [Popup.name]: Popup,
        },
        data() {
            return {
                geekNickName: "",
                assets_get_des: "",
                geekPhoto: "",
                geekAccount: "",
                invitationCode: "",
                sumUserAmount: "0.00",
                psrPrice: "0.00",
                psrPrice24: 0,
                payPassword: "",
                hasPassword: false,
                amount: "",
                toAmount: "",
                shortAmount: "",
                cnyAmount: "0.00",
                price: "",
                show: false,
                title: "设置交易密码",
                showToAvl: false,
                showShortOrder: false,
                showTransfer: false,
                showPayPasswordDialog: false
            }
        },
        mounted() {
            // this.extractInfo();
        },
        activated() {
            this.myInfo();
            this.getPsrPrice();
        },
        methods: {
            calcCny() {
                if(this.toAmount != null && this.psrPrice24 != null){
                    try {
                        this.cnyAmount = (this.toAmount * this.psrPrice24 * 0.2).toFixed(2);
                    }catch (e) {
                        console.log(e)
                    }
                }
            },
            itgToAvl() {
                if(this.toAmount == null){
                    Toast.success("请输入正确的数量");
                    return;
                }
                if(this.cnyAmount != null && this.cnyAmount > 0){
                    this.$api.user.bonusWithdraw({userId: this.$utils.getUserId(),amount:this.toAmount}).then(res => {
                        this.$callApp("ALIPAY_ORDER", res.result, (res) => {
                            console.log(res);
                            if (res.resultStatus == 9000) {
                                console.log("成功");
                                this.$callApp("OPEN_ORDER_AT_INDEX", 0, () => {});
                            } else {
                                console.log("失败");
                            }
                        });
                    }).catch(() => {

                    });
                }else{
                    Toast.success("请输入正确的数量");
                }
            },
            shortOrder() {
                if(this.shortAmount == null){
                    Toast.success("请输入正确的数量");
                    return;
                }
                if(this.shortAmount != null && this.shortAmount > 0){
                    this.showPayPasswordDialog=true;
                    this.title = "支付密码";
                }else{
                    Toast.success("请输入正确的数量");
                }
            },
            myInfo() {
                this.$api.user.myInfo({userId: this.$utils.getUserId()}).then(res => {

                    let result = res.result;
                    this.geekAccount = result.account;
                    this.geekNickName = result.nickName;
                    this.geekPhoto = result.photo;
                    if(result.payPassword2 != null && result.payPassword2 === '1'){
                        this.hasPassword = true;
                    }
                    if(result.photo == null || result.photo == undefined){
                        this.geekPhoto = "https://fuyuanapp.oss-cn-qingdao.aliyuncs.com/sailei/notes/user/478126265899409596f417d95fe88083.jpg";
                    }
                    this.invitationCode = result.inviteCode;
                }).catch(() => {

                });
            },
            getPsrPrice() {
                this.$api.user.psrPrice({userId: this.$utils.getUserId()}).then(res => {
                    this.sumUserAmount = res.result.bonusAmountAvl;
                    this.psrPrice = res.result.price;
                    this.psrPrice24 = res.result.price24;
                }).catch(() => {

                });
            },
            setPayPassword() {
                if(this.payPassword === '' || this.payPassword.length !== 6){
                    Toast.success("请输入6位交易密码");
                    return;
                }
                if(this.showTransfer){
                    this.$api.user.transfer({userId: this.$utils.getUserId(), amount: this.amount, price: this.price,payPassword: this.payPassword}).then(res => {
                        Toast.success(res.message);
                        /** 刷新可用积分 */
                        location.reload();
                    }).catch(() => {
                    });
                    this.showPayPasswordDialog = false;
                    this.showTransfer = false;
                }if(this.showShortOrder){
                    this.$api.user.addLoad({userId: this.$utils.getUserId(), amount: this.shortAmount, payPassword: this.payPassword}).then(res => {
                        Toast.success(res.message);
                        /** 刷新可用积分 */
                        location.reload();
                    }).catch(() => {
                    });
                    this.showPayPasswordDialog = false;
                    this.showShortOrder = false;

                }else{
                    this.$api.user.setPayPassword({userId: this.$utils.getUserId(), newPayPassword: this.payPassword}).then(res => {
                        Toast.success(res.message);
                        this.hasPassword = true;
                        this.showPayPasswordDialog = false;
                    }).catch(() => {
                    });
                }
            },
            transferCommit() {
                if(this.amount === '' || this.amount < 0){
                    Toast.success("请输入正确的数量");
                    return;
                }
                if(this.price === '' || this.price < 0){
                    Toast.success("请输入正确的价格");
                    return;
                }
                this.showPayPasswordDialog=true;
                this.title = "支付密码";
            },
            skipPage(title) {
                switch (title) {
                    case "账单":
                        this.$router.push(
                            {
                                name: 'InviteCode',
                                params: {
                                    nickname: this.geekNickName,
                                    invitationCode: this.invitationCode,
                                    photo: this.geekPhoto
                                }
                            }
                        )
                        break
                    case "转让":
                        if(!this.hasPassword){
                            this.payPassword ="";
                            this.showPayPasswordDialog=true;
                            this.title = "设置交易密码";
                            break;
                        }
                        this.amount = "";
                        this.price=  "";
                        this.showTransfer = true;
                        break;
                    case "转让订单":
                        this.$router.push("FishingRecord")
                        break
                    case "转为可用":
                        this.showToAvl=true;
                        break
                    /*case "短拆":
                        this.showShortOrder=true;
                        break
                    case "短拆订单":
                        this.$router.push("ShortOrder")
                        break*/
                    case "vip股东":
                        this.$router.push("fundPool")
                        break
                }
            }
        }
    }
</script>

<style scoped>
    .container {
        display: flex;
        flex-direction: column;
        margin-bottom: 6.5rem;
        padding-top: 5rem;
    }

    .info {

        background: rgba(255, 255, 255, 0.8);
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
        margin-top: 1rem;
        border-radius: 0.5rem;
    }

    .info-head {
        display: flex;
        align-items: center;
    }

    .info-reward {
        display: flex;
        justify-content: space-around;
        margin-top: 3rem;
    }
    input {
        border: none;
        outline: none;
        resize: none;
        font-size: 16px;
        height: 100%;
        width: 100%;
        background-color: #FAFAF7;
        text-align: center;
    }
</style>
